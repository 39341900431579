.centered-conatiner {
  display: flex;
  height: calc(100vh - 20px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #fafafa;
  flex-direction: column;
  overflow: hidden;
}
.centered-conatiner::-webkit-scrollbar {
  display: none;
}
.verify-btn {
  padding: 14px 20px;
  font-size: 25px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  background-color: #181d31;
  border: none;
  color: white;
  border-radius: 7px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  cursor: pointer;
  transition: all 400ms ease-in-out;
}
.verify-btn:hover {
  transform: scale(0.94);
}
.verify-btn:active {
  background-color: #bae3ea;
  color: black;
}

.centered-conatiner p {
  font-family: Inter, sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
}
.send-status-container {
  margin-top: 45px;
  width: 60%;
}
.gridDivider{
  border:1px solid silver !important;
  border-radius: 2px !important;
  }
@media only screen and (max-width: 724px) {
  .send-status-container {
    width: 90%;
  }
}
