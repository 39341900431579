.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.textField {
  flex-grow: 1;
}

.closeButton {
  position: absolute;
  top: 16px;
  right: 10px;
  cursor: pointer;
  z-index: 10;
}
.closeButton > i {
  font-size: 1.5rem;
}

.bouncing-loader {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.bouncing-loader > div {
  width: 10px;
  height: 10px;
  margin: 3px 6px;
  border-radius: 50%;
  background-color: #0756f8;
  opacity: 1;
  animation: bouncing-loader 0.6s infinite alternate;
}
@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translateY(-16px);
  }
}

.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}