.disable-row{
    pointer-events:none;
}
.divFilter{
    
    background-color:white !important;
    opacity:0.5;
}

.redemption_status{
   margin-left: 10px;
}
.conversion-header{
    width: max-content !important;
}

.hideDisplay{
    display: none;
}

/* Download Button Style  */


.download-button {
    position: relative;
    margin-left: auto;
    width: 100px;
    height: 34px;
    top: 12px;
    right: 27px;
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: #209978;
    overflow: hidden;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);
    border-radius: 4px;
    border: unset;
  }
  
  .download-button, .download-button__icon, .download-button__text {
    transition: all 0.3s;
  }
  
  .download-button .download-button__text {
    transform: translateX(22px);
    color: #ffff;
    font-weight: 500;
  }
  
  .download-button .download-button__icon {
    position: absolute;
    transform: translateX(109px);
    height: 100%;
    width: 39px;
    background-color: #17795E;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .download-button .svg {
    width: 20px;
    fill: #ffff;
  }
  
  .download-button:hover {
    background: #17795E;
  }
  
  .download-button:hover .download-button__text {
    color: transparent;
  }
  
  .download-button:hover .download-button__icon {
    width: 99px;
    transform: translateX(0);
  }
  
  .download-button:active .download-button__icon {
    background-color: #146c54;
  }
  
  .download-button:active {
    border: 1px solid #146c54;
  }
  
  .align-items-center {
    align-items: center;
   }
  
  .d-flex {
    display: flex;
  }
  
  .justify-content-between {
    justify-content: space-between;
  }

  .reasontop-line {
    border-top: 1px solid #8a8484;
    
  }

  .reasontop-line div {
    background-color: #fff;
    padding: 0 10px;
    position: relative;
    width: fit-content;
    transform: translate(-8px, -11px);
  }