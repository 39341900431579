.profile-conatiner{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    margin: 10px auto;
    padding: 1rem;
}
.profile-conatiner > div{
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 4rem;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 0.8rem;
}
.profile-picture{
    border-radius: 50%;
    height: 100px;
    width: 100px;
    object-fit: cover;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;
    margin-top: -10px;
}
.profile-pic-container{
    display: flex;
    align-items: center;
    position: relative;
}
.change-icon{
    position: absolute;
    bottom: 17px;
    right: -3px;
    padding: 0.2rem;
    background-color:rgb(55, 52, 234) ;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.change-icon > svg{
    font-size: 1.2rem !important;
    color: rgb(255, 255, 255);
}

.profile-details{
    flex-grow: 1;
}
.blur{
    -webkit-filter: blur(4px); /* Safari 6.0 - 9.0 */
    filter: blur(4px);
}
.uploading{
    border: 3px solid rgba(237, 75, 75, 0.654);
}
.uploading-svg{
    background-color: rgba(237, 75, 75, 0.862);
}
.profile-details > button{
    width: 25%;
    background-color: rgba(19, 59, 239, 0.723);
    /* margin: 40px auto !important; */
    margin-left: auto !important;
    text-transform: capitalize;
    font-size: 0.85rem;
}
.profile-details > button:hover{
    background-color: rgba(19, 59, 239, 1);
}

@media only screen and (max-width: 440px) {
    .profile-conatiner{
        width: auto;
    }
    .profile-conatiner > div{
        flex-direction: column;
        justify-content: center;
    }
    .profile-details > button{
        width: 100%;
    }
}