.field-border {
  background: #ffffff;
  border: 2px solid #f0f0f0;
  border-radius: 10px;
  padding: 10px 16px;
}
.form-label {
  font-weight: 500;
  font-size: 10.7083px;
  line-height: 18px;
  /* identical to box height, or 167% */

  /* Neutral/100 */

  color: #1a1a1a;
}
.persmission-button {
  background: #ffffff !important;
  border: 2px solid #f0f0f0 !important;
  border-radius: 10px !important;
  color: black !important;
  text-transform: capitalize !important;
  padding: 6px 16px !important;
}
