.dropdown-option {
    border: 2px solid #e6e6eb !important;
    border-radius: 8px !important;
    margin-inline: 10px !important;
    padding: 10px 0.9rem !important;
    font-weight: 600 !important;
    margin-bottom: 0.5rem !important;
  
    font-weight: 500;
    color: #1a1a1a;
    font-size: 14px;
    line-height: 20px;
    text-transform: capitalize;
    width: auto;
    margin-bottom: 0.8rem;
  }
  .dropdown-option:hover {
    background: #0064c16f !important;
    color: white !important;
  }
  
  .dropdown-heading {
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 24px;
    color: #1a1a1a;
    margin: 20px 24px;
  }
  
  .option-text > .MuiListItemText-primary {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #1a1a1a;
  }
  