/* .graph-containder{
    position: relative;
    margin: 60px auto !important;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 1rem;
    border-radius: 0.5rem;
} */

.circular-graph-containder
{
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0 20px !important;
    /* width:auto; */
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 1rem;
    border-radius: 0.5rem;  
}
.closing-questions
{
    position: relative;
    margin: 0 20px !important;
    margin-top: 20px !important;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 1rem;
    border-radius: 0.5rem; 
}
.trending-questions
{
    position: relative;
    margin: 0 20px !important;
    margin-top: 20px !important;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 1rem;
    border-radius: 0.5rem; 
}
.total-trades,.kyc-mgmt
{
    margin: 0 8px !important;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 1rem;
    border-radius: 0.5rem; 
}
.kyc-mgmt
{
    margin-top: 20px !important;
}
.total-wallet-transactions
{
    /* height: 80px; */
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between !important;
}
.first-div
{
    width: 100%;
    justify-content: space-between;
}
.container-main
{
    width: auto;
    display: flex;
    flex-direction: row;
    gap: 0.8rem;
    justify-content: space-between;
    padding-right: 100px;
}
.second-box
{
    width: 35%;
}
.first-box
{
    flex-grow: 1;
}
.graph
{
    height: 175px;
    width: 175px;
    margin-left: 50px;
    /* position: relative;
    top:20px; */
}
.stats
{
    height: auto;
    width:250px;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-right: 50px;
}
.graph-and-stats
{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.third-div
{
    display:flex ;
    flex-direction: column;
}
.third-div-1,.third-div-2
{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.third-div-2
{
    margin-left: 20px;
}
.color-and-in,.color-and-out
{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.both-totals
{
    display:inline-flex;
    justify-content: space-between !important;
}

.closing-1
{
    height: 80px;
    width: 95%;
    position: absolute;
    top:80px;
    padding:10px;
    background-color:gainsboro;
    border-radius: 10px;
}
.closing-2
{
    height: 80px;
    width: 95%;
    position: absolute;
    top:180px;
    padding:10px;
    background-color:gainsboro;
    border-radius: 10px;
    justify-content:space-evenly;
}
.trending1
{
    height: 80px;
    width: 95%;
    position: absolute;
    top:80px;
    padding:10px;
    background-color:gainsboro;
    border-radius: 10px;
    justify-content:space-evenly;
}
.trending2
{
    height: 80px;
    width: 95%;
    padding: 10px;
    position: absolute;
    top:180px;
    background-color: gainsboro;
    border-radius: 10px;


}
.count
{
    display: flex;
    justify-content: center;
}
.daily-count
{
    line-height: 10rem;
    font-weight: 500;
    font-size: 64px;
}
.kyc-items
{
    display: inline-flex;
    justify-content: space-evenly;
    background-color: gainsboro;
    padding: 10px;
    border-radius: 10px;
    margin-top: 10px;

}
.approve-button
{
    color: white !important;
    background-color: black !important;
}