.disableField
{
    pointer-events: none;
   background-color:#f6f6f6;
}
.disableField .MuiAutocomplete-root{
    background-color:#f6f6f6 !important;

}

.panelistFormDiv  label{
    padding: 1.5px !important;
    pointer-events:none !important;
}

.panelistBox {
    align-items: center;
}
.panelistBox .toggleBtn{
  padding-left: 12px;
}

.copiedInput {
    background-color: #f6f6f6;
    border: unset;
    pointer-events: none; /* Disable the input field */
    position: relative; /* For positioning hover effects */
  }
  
  .copiedInput div {
    padding: 7.5px 14px !important;
  }

  .redeemTable {
    width: 100%;
    overflow: hidden;
  }