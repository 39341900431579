*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.progress {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 200px);
}
.not-found-page {
  flex-direction: column;
  height: calc(100vh - 140px);
}
.not-found-page button {
  margin-top: 40px;
}
.not-found-page h1 {
  font-family: "Poppins", sans-serif !important;
  font-weight: 700;
  font-size: 9rem;
  letter-spacing: 0.2rem;
}

.not-found-page a {
  text-decoration: none;
  color: white;
  font-weight: 400;
  font-size: 1.1rem;
  padding: 0.7rem 1.1rem;
}
.rm-text-decor {
  text-decoration: none;
  color: inherit;
}
.loader {
  position: "absolute";
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}

.flex-row {
  display: flex;
  flex-direction: row;
}
.center-text {
  text-align: center;
}

/* .message-status p {
  font-family: Inter, sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
} */
.app-bar-logo {
  width: 373px;
  height: 76%;
}
.app-bar-logo > img {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 440px) {
  .app-bar-logo {
    width: 100%;
    padding: 0 18px;
  }
}
