.addButton {
  display: block;
  margin-right: auto !important;
  width: 108px;
  height: 41px;
  border-radius: 10px;
  font-size: 14px;
  color: #f5f5f5;
  background: #1a1a1a;
  transition: 200ms ease-in-out;
  cursor: pointer;
}
.addButton.outline {
  background: none;
  color: black;
}
.get-sample {
  width: auto;
  padding: 10px 16px;
  margin: 5px 0 5px;
  margin-left: auto;
  margin-right: 0 !important;
}
.addButton:hover {
  color: black;
  background-color: white;
}
.addButton:disabled:hover,
.addButton:disabled {
  color: white;
  background-color: rgb(44, 42, 42);
}
.addButton.outline:hover {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 0, 0);
}
.addButton.outline:disabled:hover,
.addButton.outline:disabled {
  color: black;
  background-color: rgb(192, 192, 192);
}
.survey-email .css-10hburv-MuiTypography-root{
  font-size: 14px !important;
  white-space: nowrap;
}

.survey-email .MuiOutlinedInput-input{
  padding: 4px !important;
}

.survey-email .survey-emailNum {
  font-size: 14px !important;
}
.surveyEmail-header{
  border-bottom: 1px solid silver !important;
}

.surveyEmail-header .MuiGrid-root{
  padding: 8px;
}

.surveyEmail-parent .css-1t704d9-MuiStack-root{
  padding: 8px;
  border: 1px solid silver;
  border-radius: 4px;
  box-shadow: 0px 0px 1px 0px;
  width: 25%;
  margin-left: 12px;
  margin-right: 12px;
}
.surveyEmail-parent .css-15pb5dm{
  padding: 8px;
  border: 1px solid silver;
  border-radius: 4px;
  box-shadow: 0px 0px 1px 0px;
  width: 25%;
  margin-left: 12px;
  margin-right: 12px;
}

.email-table th{
  font-size: 12px;
}

.survey-emailHistory .dbPzbv div:first-child{
    font-size: 12px;
    white-space: unset !important;
}

.survey-emailHistory .jINVri{
  min-width: unset !important;
  width: 12% !important;
}
/* .survey-emailHistory .jINVri:nth-child(1){
  width: 12% !important;
} */
.survey-emailHistory .jINVri:nth-child(7){
  font-size: 11px;
  white-space: normal !important;
}
.survey-emailHistory .jINVri:nth-child(8){
  font-size: 11px;
  white-space: normal !important;
}

.select-btn{
  padding: 8px;
  font-size: 12px;
  display: flex;
}
.select-btn .form-check{
  display: contents;
  margin-left: 12px;
}


/* .survey-emailHistory .sc-hLljAB{

  padding-right: 0px !important;
}
.survey-emailHistory .sc-kDLnty{
  display: none !important;
} */

