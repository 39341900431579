.loginFormContainer {
  background-color: rgb(247, 241, 241);
  margin: auto;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: rgba(249, 249, 250, 0.2) 0px 7px 29px 0px;
  width: 500px;
}
.loginForm {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.forgortLink {
  cursor: pointer;
}
.loginBtn {
  padding: 10px !important;
}
.loginBtnLoading {
  padding: 5px !important;
}
.errorMsg {
  background-color: rgba(39, 35, 35, 0.886) !important;
  color: white !important;
}
