.form{
    padding: 20px 16px;
    margin: 16px;
    position: relative;
}
.textField{
    width: 100%;
}
.textarea{
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.1rem;
    resize: none;
    font-family: sans-serif;
    padding: 10px;
    font-size: 1rem;
}
.loader{
    position: 'absolute';
    top: 50%;
    right: 50%;
    transform: translate(50%,-50%);
}