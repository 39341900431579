/* Hide scrollbar for Chrome, Safari and Opera */
.snackbar > div::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.snackbar > div {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
    height: calc(100vh - 20px);
    border-radius: 0.5rem;
    position: fixed;
    left: 15px;
    top: 12px;
    text-align: center;
  }
.link-text{
    color: #C2C2C2;
    text-decoration: none;
}
.link-text-dark{
    text-decoration: none;
    color: white;
}
.sidebar-child{
    padding-left: 15px !important;
}
ul{
    padding: 0 !important;
}

.list-item{
    display: flex !important;
    flex-direction: row;
    justify-content: space-between !important;
}
.list-item a{
    flex-grow: 1;
}
.list-item-text > span{
    width: 180px;
    overflow: hidden;
    color: #c2c2c2;
}
.sidebar-child .list-item-text > span{
    width: 150px;
    overflow: hidden;
}

.list-item i{
    font-size: 1.5rem;
    color: #c2c2c2;
}
.list-item a{
    transition: 300ms ease-in-out;
}

.list-item a:hover{
    /* background-color: #789ef27d; */
    border-radius: 0.5rem;
}

.active i, .active span{
    color: #1A1A1A;
}

.list-item a.active {
    /* background-color: #2364f097; */
    border-radius: 0.5rem;
    margin: 6px 0;
}